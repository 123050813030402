:root {
    --navbar-height: 4rem;
}

.navbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 0;
    width: 100%;
    height: var(--navbar-height);
    padding: 0 2rem;
}

.navbar .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-text-color);
    padding: 0;
    margin-left: 1rem;
}

.navbar .btn svg {
    font-size: 1.5rem;
}

.navbar .btn:focus {
    box-shadow: none;
}

.navbar .btn.go-back {
    border: 1px solid rgba(255, 255, 255, 0.384);
    border-radius: 1rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
}

.navbar .navbar-left,
.navbar .navbar-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.navbar .navbar-left {
    width: 75%;
    justify-content: flex-start;
}

.navbar .navbar-buttons {
    width: 25%;
    justify-content: flex-end;
}


.navbar .logo {
    margin-right: 1rem;
    max-height: 2rem;
}

.btn.spotify-login {
    background-color: #1DB954;
    font-weight: 800;
    border-radius: 1rem;
    padding: .2rem .5rem;
    font-size: 1rem;
}

@media screen and (max-width: 960px) {
    .navbar .navbar-left {
        width: calc(100% - 2rem);
        justify-content: flex-start;
    }

    .navbar .navbar-buttons {
        width: 2rem;
        justify-content: flex-end;
    }
}